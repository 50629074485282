import emoji from "react-easy-emoji";

const greeting = {
    username: "Sattyam Jain",
    title: "Hi all, I'm Sattyam",
    subTitle: emoji("I'm a dynamic Senior Software Developer, currently thriving as a SDE III at Zenarate. I boast a solid background in Python, AI/ML, and DevOps, with a track record of leading high-impact projects. I am versatile in various programming languages, demonstrates strong leadership skills, and has made notable open-source contributions. My career includes significant achievements and innovative project development, underpinned by a strong educational foundation and continuous professional development."),
    resumeLink: "https://docs.google.com/document/d/1ap83rGk001g8QwTBWNfBxTzc2Ed7yDVlH2bM_v_r2cQ/edit?usp=sharing"
};

const socialMediaLinks = {
    github: "https://github.com/sattyamjjain",
    linkedin: "https://www.linkedin.com/in/sattyamjain/",
    gmail: "sattyamjain96@gmail.com",
    medium: "https://medium.com/@sattyamjjain",
    devCommunity: "https://dev.to/sattyamjjain",
    twitter: "https://twitter.com/Sattyamjjain",
    instagram: "https://twitter.com/SattyamJ"
};

const skillsSection = {
    title: "What I do",
    subTitle: "EXPERIENCED SENIOR SOFTWARE DEVELOPER WHO WANTS TO EXPLORE EVERY NEW TECH TREND Like GenAI/ML, DevOps/MLOps, etc.",
    skills: [
        emoji("⚡ Accomplished Senior Software Developmer with advanced skills in Python, Java, and ReactJS, and a strong focus on full-fledged software development."),
        emoji("⚡ Expert in machine learning, data structures, and algorithms, driving innovation in software performance."),
        emoji("⚡ Proficient in a range of technologies, including MySQL, MongoDB, AWS, GCP, and Flutter."),
        emoji("⚡ Skilled in system integration using RestAPI, Git, Docker, and other DevOps tools, with expertise in JetBrains Tools and TypeScript."),
        emoji("⚡ Strong in project management and agile methodologies, with demonstrated leadership in team coordination and task management."),
        emoji("⚡ Innovative in statistical modeling and data visualization, with a commitment to problem-solving and quality software delivery."),
        emoji("⚡ Continuously engaged in professional growth, staying abreast of emerging technologies and software development trends.")
    ],

    softwareSkills: [
        {
            skillName: "Python",
            fontAwesomeClassname: "fab fa-python"
        },
        {
            skillName: "aws",
            fontAwesomeClassname: "fab fa-aws"
        },
        {
            skillName: "Git",
            fontAwesomeClassname: "fab fa-git"
        },
        {
            skillName: "MySql",
            fontAwesomeClassname: "fas fa-database"
        },
        {
            skillName: "TypeScript",
            fontAwesomeClassname: "fab fa-js"
        },
        {
            skillName: "reactjs",
            fontAwesomeClassname: "fab fa-react"
        },
        {
            skillName: "Java",
            fontAwesomeClassname: "fab fa-java"
        },
        {
            skillName: "docker",
            fontAwesomeClassname: "fab fa-docker"
        },
        {
            skillName: "jira",
            fontAwesomeClassname: "fab fa-jira"
        },
    ]
};

const techStack = {
    viewSkillBars: true,
    experience: [
        {
            Stack: "Software Development",
            progressPercentage: "90%"  // Reflects combined frontend and backend skills
        },
        {
            Stack: "Advanced Programming",
            progressPercentage: "90%"  // Highlights strong programming capabilities
        },
        {
            Stack: "AI & Machine Learning",
            progressPercentage: "75%"  // Represents your proficiency in AI/ML
        },
        {
            Stack: "Cloud & DevOps",
            progressPercentage: "80%"  // Showcases expertise in AWS/GCP and DevOps practices
        },
        {
            Stack: "Project Leadership & Management",
            progressPercentage: "85%"  // Emphasizes skills in project management and leadership
        }
    ]
};

const workExperiences = {
    viewExperiences: true,
    experience: [
        {
            role: "Software Development Engineer III",
            company: "Zenarate",
            companylogo: require("./assets/images/zenarate.jpeg"),
            date: "Dec 2023 - Present",
            desc: "Leading advanced software projects utilizing Python, Java, and ReactJS; focusing on enhancing AI/ML capabilities.",
            descBullets: [
                "Expert in integrating advanced technologies for optimal performance",
                "Driving efficiency and innovation in software solutions",
                "Pioneering in cloud integration and agile development methodologies"
            ]
        },
        {
            role: "Software Developer Team Lead",
            company: "Myshubhlife",
            companylogo: require("./assets/images/myshubhlife.png"),
            date: "Dec 2020 - Nov 2023",
            desc: "Led a team in developing and optimizing SAAS solutions, improving processes and reducing dependencies.",
            descBullets: [
                "Mastered Python and AWS to enhance software development",
                "Streamlined partner onboarding, improving efficiency by 50%",
                "Cultivated team success through effective leadership and decision-making"
            ]
        },
        {
            role: "Full Stack Developer (Freelance)",
            company: "IDRONECT",
            companylogo: require("./assets/images/idronect.png"),
            date: "Sep 2020 – Nov 2020",
            desc: "Enhanced drone operation efficiency through a full-stack web application utilizing ReactJS and NodeJS.",
            descBullets: [
                "Optimized drone control and mapping using CesiumJs for high performance and precision",
                "Developed a drone simulator, showcasing technical proficiency in remote collaboration",
                "Delivered end-to-end development and implementation for an international client"
            ]
        },
        {
            role: "Full Stack Developer (Contract)",
            company: "Personal Client (Allahabad)",
            companylogo: require("./assets/images/shuklaMasala.png"),
            date: "July 2020 – Sep 2020",
            desc: "Developed a comprehensive E-commerce platform, focusing on agile methodologies and customer needs.",
            descBullets: [
                "Crafted an end-to-end solution for e-commerce activities including admin panel management",
                "Effectively managed project requirements, ensuring successful platform integration",
                "Leveraged a wide array of technologies to deliver a user-friendly shopping experience"
            ]
        },
        {
            role: "Software Developer Intern",
            company: "Actonate Private Solution Limited",
            companylogo: require("./assets/images/actonate.png"),
            date: "January 2020 – July 2020",
            desc: "Gained valuable experience in collaborative software development, focusing on designing and publishing applications.",
            descBullets: [
                "Involved in diverse projects using React, Electron, and Postman, enhancing application design and deployment",
                "Played a key role in feature development and team-based problem solving",
                "Contributed to the development of a streaming platform, showcasing adaptability and technical skills"
            ]
        }
    ]
};

const openSource = {
    githubConvertedToken: process.env.REACT_APP_GITHUB_TOKEN,
    githubUserName: "sattyamjjain",
    showGithubProfile: "true"
};

const achievementSection = {

    title: "Achievements And Certifications",
    subtitle: "A showcase of my achievements, certifications, award letters, and some cool stuff that I've accomplished!",

    achivementsCards: [
        {
            title: "Deep Learning Specialization - Andrew Ng",
            subtitle: "Completed an in-depth specialization in Deep Learning, mastering neural network architectures, and their real-world applications using Python and TensorFlow.",
            image: require("./assets/images/deepLearningSpecialization.png"),
            footerLink: [
                // If there are any footer links related to this achievement, they go here
            ]
        },
        {
            title: "International summit on Data Science and AI at RBCDSAI, IIT MADRAS",
            subtitle: "A premier event on Data Science and AI bringing together global thought leaders from academia, industry, and research labs.",
            image: require("./assets/images/rbcdsai.png"),
            footerLink: [
                {
                    name: "Certification",
                    url: "https://drive.google.com/file/d/1IVaF6Xcnwu68T9Sb3NqIr5kuFoZ5wFv2/view?usp=sharing"
                }
            ]
        },
        {
            title: "Artificial Intelligence: Responsible AI for Social Empowerment(RAISE2020)",
            subtitle: "We need to Artificial Intelligence in INDIA and Artificial Intelligence work for INDIA",
            image: require("./assets/images/raise2020.jpg"),
            footerLink: [
                {
                    name: "Certification",
                    url: "https://drive.google.com/file/d/15HJ9zSJKzXw_m-v6y1KJaNWDhN8_DqEH/view?usp=sharing"
                }
            ]
        },
        {
            title: "O Level Course Organized NIELIT (DOEACC)",
            subtitle: "Completed O level Course organized by NIELIT (DOAECC) Jhansi, U.P",
            image: require("./assets/images/nielit.jpg"),
            footerLink: [{
                name: "Certification",
                url: "https://drive.google.com/file/d/1SICHL-sYMQABXDP4uaWneUbzrpgqcVNs/view?usp=sharing"
            }]
        },
        {
            title: "i5 summit, Largest Entrepreneurship summit of Central India, IIT & IIM Indore",
            subtitle: "It serves as a platform for our generation’s dreamers to pitch their ideas to seasoned venture capitalists and listen to success stories of those who have made it big with their entrepreneurial ventures.",
            image: require("./assets/images/i5summit.jpg"),
            footerLink: [{
                name: "Certification",
                url: "https://drive.google.com/file/d/1ZGbhaKjfZ_2_cHWmYFDZuz3I9DKyGQD3/view?usp=sharing"
            }]
        },
        {
            title: "Attended  India’s Largest Product Summit ‘THE MAKERS SUMMIT’ by Inc42 Plus",
            subtitle: "It is India's largest product conference that explores revolutionary products shaping the future, featuring leaders and makers of India's fastest growing startups, and providing tools, insights, opportunities, and connections for enthusiasts, investors, and founders.",
            image: require("./assets/images/inc42.jpeg"),
            footerLink: []
        },
        {
            title: "ACM Chapter HACKATHON",
            subtitle: "Engaged in a competitive web development hackathon, honing my skills and contributing to collaborative projects.",
            image: require("./assets/images/acm.png"),
            footerLink: [
                {
                    name: "View Certificate",
                    url: "https://drive.google.com/file/d/1icvBwdHBj7CcYsK-CO1dASGyQrCOh2Sk/view?usp=sharing"
                }
            ]
        },
        {
            title: "Coding Champ Competition, Prestige Gwalior",
            subtitle: "First Runner Up winner at Coding Champ Competiton at Prestige College, Gwalior",
            image: require("./assets/images/prestige.jpg"),
            footerLink: [
                {
                    name: "Certification",
                    url: "https://drive.google.com/file/d/17ME0P7MWP1uTzRGawZvRgQU5uOMDCoEm/view?usp=sharing"
                }
            ]
        },
        {
            title: "CCC Course Organized NIELIT (DOEACC)",
            subtitle: "Completed CCC Course organized by NIELIT (DOAECC) Jhansi, U.P",
            image: require("./assets/images/nielit.jpg"),
            footerLink: []
        }
    ]
};

const contactInfo = {
    title: emoji("Contact Me ☎️"),
    subtitle: "Discuss a project or just want to say hi? My Inbox is open for all.",
    number: "+91-9140675155, +91-9616859977",
    email_address: "sattyamjain96@gmail.com"
};

export {
    greeting,
    socialMediaLinks,
    skillsSection,
    techStack,
    workExperiences,
    openSource,
    achievementSection,
    contactInfo
};
