import React from "react";
import "./SocialMedia.css";
import {socialMediaLinks} from "../../portfolio";

export default function socialMedia() {
    return (
        <div className="social-media-div">

            {socialMediaLinks.github ?
                (<a href={socialMediaLinks.github} className="icon-button github" target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-github"></i>
                    <span></span>
                </a>)
                :
                null}

            {socialMediaLinks.linkedin ?
                <a href={socialMediaLinks.linkedin} className="icon-button linkedin" target="_blank"
                   rel="noopener noreferrer">
                    <i className="fab fa-linkedin-in"></i>
                    <span></span>
                </a>
                :
                null}

            {socialMediaLinks.gmail ?
                <a href={`mailto:${socialMediaLinks.gmail}`} className="icon-button google" target="_blank"
                   rel="noopener noreferrer">
                    <i className="fab fa-google"></i>
                    <span></span>
                </a>
                :
                null}

            {/*{socialMediaLinks.gitlab ?*/}
            {/*    <a href={socialMediaLinks.gitlab} className="icon-button gitlab" target="_blank"*/}
            {/*       rel="noopener noreferrer">*/}
            {/*        <i className="fab fa-gitlab"></i>*/}
            {/*        <span></span>*/}
            {/*    </a>*/}
            {/*    :*/}
            {/*    null}*/}

            {socialMediaLinks.instagram ?
                <a href={socialMediaLinks.instagram} className="icon-button instagram" target="_blank"
                   rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                    <span></span>
                </a>
                :
                null}

            {socialMediaLinks.twitter ?
                <a href={socialMediaLinks.twitter} className="icon-button twitter" target="_blank"
                   rel="noopener noreferrer">
                    <i className="fab fa-twitter"></i>
                    <span></span>
                </a>
                :
                null}

            {socialMediaLinks.medium ?
                (<a href={socialMediaLinks.medium} className="icon-button medium" target="_blank"
                    rel="noopener noreferrer">
                    <i className="fab fa-medium"></i>
                    <span></span>
                </a>)
                :
                null}

            {socialMediaLinks.devCommunity ?
                (<a href={socialMediaLinks.devCommunity} className="icon-button dev-community" target="_blank"
                    rel="noopener noreferrer">
                    <i className="fa-brands fa-dev"></i>
                    <span></span>
                </a>)
                :
                null}

        </div>
    );
}
