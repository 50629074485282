import React, {useState, useEffect, lazy, Suspense} from "react";
import ApolloClient from "apollo-boost";
import {gql} from "apollo-boost";
import "./Project.css";
import Button from "../../components/button/Button";
import Loading from "../loading/Loading";
import {openSource, socialMediaLinks} from "../../portfolio";
import {InMemoryCache} from "@apollo/react-hooks";

export default function Projects() {
    const GithubRepoCard = lazy(() => import('../../components/githubRepoCard/GithubRepoCard'));
    const FailedLoading = () => <div className="error-message">
        <p>Projects could not be retrieved at this time.</p>
        <p>Please check your connection and try again shortly.</p>
    </div>;
    const renderLoader = () => <Loading/>;
    const [repo, setrepo] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        let isMounted = true; // Flag to check if the component is mounted
        const client = new ApolloClient({
            uri: "https://api.github.com/graphql",
            cache: new InMemoryCache(),
            request: (operation) => {
                operation.setContext({
                    headers: {
                        authorization: `Bearer ${openSource.githubConvertedToken}`,
                    },
                });
            },
        });

        const fetchData = async () => {
            if (isMounted) { // Only run if the component is still mounted
                try {
                    const result = await client.query({
                        query: gql`
            {
              user(login: "${openSource.githubUserName}") {
                pinnedItems(first: 6, types: [REPOSITORY]) {
                  totalCount
                  edges {
                    node {
                      ... on Repository {
                        name
                        description
                        forkCount
                        stargazers {
                          totalCount
                        }
                        url
                        id
                        diskUsage
                        primaryLanguage {
                          name
                          color
                        }
                      }
                    }
                  }
                }
              }
            }
          `,
                    });
                    setrepo(result.data.user.pinnedItems.edges);
                } catch (error) {
                    console.error("Error Fetching Data: ", error);
                    setError(true);
                }
            }
        };

        fetchData();

        return () => {
            isMounted = false; // Set the flag to false when the component unmounts
        };
    }, [openSource.githubConvertedToken, openSource.githubUserName]);


    if (error) {
        return <FailedLoading/>;
    }

    if (!(typeof repo === 'string' || repo instanceof String)) {
        return (
            <Suspense fallback={renderLoader()}>
                <div className="main" id="opensource">
                    <h1 className="project-title">Open Source Contribution</h1>
                    <div className="repo-cards-div-main">
                        {repo.map((v, i) => {
                            return <div key={v.node.id}><GithubRepoCard repo={v} key={v.node.id}/></div>;
                        })}
                    </div>
                    <Button text={"More Projects"} className="project-button" href={socialMediaLinks.github}
                            newTab={true}/>
                </div>
            </Suspense>
        );
    } else {
        return (<FailedLoading/>);
    }
}
