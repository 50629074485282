import React, {useState, useEffect, lazy, Suspense} from "react";
import ApolloClient, {gql} from "apollo-boost";
import {openSource} from "../../portfolio";
import Loading from "../loading/Loading";

const client = new ApolloClient({
    uri: "https://api.github.com/graphql",
    request: (operation) => {
        operation.setContext({
            headers: {
                authorization: `Bearer ${openSource.githubConvertedToken}`,
            },
        });
    },
});

const renderLoader = () => <Loading/>;
const GithubProfileCard = lazy(() => import('../../components/githubProfileCard/GithubProfileCard'));

export default function Profile() {
    const [prof, setProf] = useState(null);

    useEffect(() => {
        if (openSource.showGithubProfile) {
            client.query({
                query: gql`
          {
            user(login: "${openSource.githubUserName}") {
              name
              bio
              isHireable
              avatarUrl
              location
            }
          }
        `,
            })
                .then((result) => {
                    setProf(result.data.user);
                })
                .catch((error) => {
                    console.error("Error fetching GitHub profile: ", error);
                    setProf(null);
                });
        }
    }, [openSource.githubConvertedToken, openSource.githubUserName, openSource.showGithubProfile]);

    if (!prof) {
        return renderLoader();
    }

    return (
        <Suspense fallback={renderLoader()}>
            <GithubProfileCard prof={prof} />
        </Suspense>
    );
}
